.App {
  box-sizing:border-box;
  margin:0;
  padding:0;
  overflow-x:hidden;
  background:#f4f5f7;
  font-size:18px;
  display:flex;
  flex-direction:column;
  flex-wrap:wrap;
}

.header {
  width:100%; 
  height:60px;
  display:flex; 
  flex-direction:row; 
  justify-content:flex-start;
  padding:0;
  overflow:hidden;
  z-index:2; 
}
.header-logo-wrapper {
    width:20%;
}
.header-logo-container {
  width:100%;
  height:100%;
  font-family:Helvetica;
  color:#253858;
  font-size:26px;
  font-weight:700;
  font-style:italic;
  margin:0;
  padding:0;
  text-align:center;
  line-height:60px;
  
}
.hero{
  display:flex;
  justify-content:center;
  
}
.hero-text-wrapper{
  width:50%;
  min-width:640px;
 
}
.hero-text {
  color:#253858;
  font-size:20px;
 text-align:center;
 margin:0;
}
.paragraph-tag {
  font-size:18px;
  font-weight:bolder;
  color:red;
}
.form-wrapper {
  display:flex; 
  flex-direction:column; 
}
.textarea-wrapper-wrapper {
  display:flex;
  flex-direction:row; 
  flex-wrap:wrap; 
  justify-content:space-around;
}
.textarea-wrapper {
  display:flex;
  flex:40%;
  flex-direction:column;
  padding:10px;
}
.textarea-label-container {
  display:flex;
  align-items:center;
  justify-content:center;
  font-size:1.4em;
  background:#333; 
  color:#fff
}
.textarea-field-container {
  display:flex;
  justify-content:center;
}
.textarea {
  width:100%;
  min-width:640px;
  height:300px;
  font-family:Arial, Helvetica;
  padding:10px 20px;
  margin:0;
  resize:none;
}

.submit-wrapper {
  display:flex;
  justify-content:center;
  padding:10px;
}

.btn {
  min-width:150px;
  height:40px;
  background:green;
  color:#fff;
  font-weight:bolder;
  font-size:18px;
  padding:5px 15px;
  min-width:10ch;
  min-height:44px;
  display:inline-flex;
  justify-content:flex-start;
  align-items:center;
  text-align:center; 
  line-height:1.1;
  border:none;
  border-radius:4px;
  cursor:pointer;   
}

.submitter {
  background:#50b83c;
}

.footer{
  background:#333;
  color:#fff;
  font-weight:500;
  width:100%;
  height:70px;
  display:flex;
  flex-direction:row;
  justify-content:flex-end;
  position:relative;
  bottom:0;
}

.copyright{
  width:33.3%;
  display:flex;
  justify-content:center;
  align-items:center;
}

.privacy-policy{
  width:33.3%;
  display:hidden;
  justify-content:center;
  align-items:center;
}
.borderLeft {
  border-left:"1px solid red";
}

.borderRight {
  border-right:"1px solid blue";
}



